import React, { useEffect, useState, useContext } from "react";
import { Modal, Button, Spinner, Table } from "react-bootstrap";
import SearchBar2 from "../PageLayout/SearchBar2";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import UpdateRecordModal from "./UpdateRecordModal";
import ViewModalEleve from "./ViewModalEleve";
import CreateRecordModal from "./CreateRecordModal";
import PayementRecord from "./PayementRecord";
import moment from "moment";
import "moment/locale/fr";
import CurrencyComponent from "../../Utils/CurrencyComponent";
import { DataContext } from "../../Context/DataProvider";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faDollarSign, faTrash, faEdit, faAdd } from '@fortawesome/free-solid-svg-icons';
import { get, post, del } from "../../Services/Api";

export default function ViewRecordModal({ isOpen, onClose, data }) {
  const [elevesData, setElevesData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [itemId, setItemId] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const apiUrl = "/presms/classroom"; // Set the API URL as needed
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const { academicYears, categories, userData } = useContext(DataContext); // Use your DataContext
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [eleveId, setEleveId] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [labelStyles, setLabelStyles] = useState({});
  const [presenceStatus, setPresenceStatus] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  if (userData && userData.length){
    console.log("userdata from createrecordModal", userData[0])
  }else{
    console.log("no dispo")
  }
  /// ---------Start presence code here-------- ////
  const formatDateToHumanReadable = (date) => {
    return moment(date).locale("fr").fromNow();
  };

  const handlePresenceChange = (eleveId, isChecked) => {
    setPresenceStatus((prevStatus) => ({
      ...prevStatus,
      [eleveId]: isChecked,
    }));

    setLabelStyles((prevStyles) => ({
      ...prevStyles,
      [eleveId]: isChecked ? { color: "green" } : { color: "red" },
    }));
  };

  const createPresenceRecord = (eleveId, status) => {
    const presenceData = {
      eleve: eleveId,
      status: status,
    };

    return post("/presms/presences/", presenceData)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error(`Error creating presence record for eleve ${eleveId}:`, error);
      });
  };
  const savePresenceStatus = () => {
    setIsLoading(true);
    const createRecordPromises = elevesData.map((item) => {
      const eleveId = item.id;
      const isChecked = presenceStatus[eleveId] || false;
      const status = isChecked ? "present" : "absent";

      return createPresenceRecord(eleveId, status);
    });

    Promise.all(createRecordPromises)
      .then(() => {
        console.log("Presence records created successfully.");
        setSuccessMessage("Presence records saved successfully.");
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error creating presence records:", error);
        setIsLoading(false);
      });
  };
  /// -----------End presence code here------------ ////

  const calculateTotalPayment = () => {
    if (elevesData && data) {
      // Calculate the total payment by multiplying the number of students by total_amount
      return elevesData.length * data.total_amount;
    }
    return 0;
  };

  const openModal = (eleveId) => {
    setModalIsOpen(true);
    setEleveId(eleveId); // Set the eleve_id in state
    console.log("eleveId is: ", eleveId);
  };
  const handleSearch = (searchTerm) => {
    if (searchTerm.trim() === '') {
      setFilteredData(elevesData);
    } else {
      const filtered = elevesData.filter(eleve =>
        eleve.id.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
        eleve.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        eleve.last_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
    }
    console.log("Value filteredData in  VIEWRecordModal", filteredData);
  };
  
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const handleCreate = (newRecord, eleveId) => {
    // Update elevesData with the new payment data
    setElevesData((prevData) => ({
      ...prevData,
      total_amount_payed: prevData.total_amount_payed + newRecord.payement_amount,
      // Add other properties you want to update based on the new record
    }));
    // Add logic to associate the new payment with the specific eleve_id
    console.log("Creating payment for eleve with ID:", eleveId);
    console.log("New payment data:", newRecord);
    // Close the modal
    closeModal();
  };
  const handleView = (id) => {
    console.log("Viewing eleve with ID:", id);
    setItemId(id);
    console.log("Viewing eleve with ID in variable ItemId:", itemId);
    setIsViewModalOpen(true);
  };
  useEffect(() => {
    // This will run once after the initial render
    console.log("Viewing eleve with ID in variable ItemId:", itemId);
  }, [itemId]);
  const handleCloseView = () => {
    setItemId(null);
    setIsViewModalOpen(false);
  };
  const getYearNameById = (id) => {
    // Find the academic year by ID and return its year_name
    const year = academicYears.find((year) => year.id === id);
    return year ? year.year_name : "N/A";
  };


  const getCategoryNameById = (id) => {
    // Find the category by ID and return its category_name
    const category = categories.find((category) => category.id === id);
    return category ? category.category_name : "N/A";
  };
 
    useEffect(() => {
        // Fetch eleves-by-class data when the modal is opened
        if (isOpen || data) {
            setLoading(true); // Set loading to true while fetching data

            // Generate a unique timestamp to ensure a fresh request
            const timestamp = Date.now();
            
            fetch(`https://hmdgn.com/presms/eleves-by-class/${data.id}/?timestamp=${timestamp}`)
                .then((response) => response.json())
                .then((fetchedData) => {
                    setElevesData(fetchedData);
                    setFilteredData(fetchedData);
                    console.log("this is the Updated data in the view", fetchedData);
                })
                .catch((error) => console.error("Error fetching eleves data:", error))
                .finally(() => setLoading(false)); // Set loading to false when data is fetched
        }
    }, [isOpen, data]);

  // Log changes in elevesData
  useEffect(() => {
    console.log("Eleves data by Class:", elevesData);
    console.log("Class information:", data);
  }, [elevesData, data]);

  return (
    <div>
      {isOpen && !modalIsOpen && ( // Render ViewModalEleve only if isOpen and !modalIsOpen
      <Modal show={isOpen} onHide={onClose} className="custom-modal-width" size="xl">
        <Modal.Header closeButton className="custom-modal-header">
        <Modal.Title>
          Classe: {data ? data.class_name : ''} -||-
          Nombre D'eleves: {elevesData ? (elevesData.length || 0) : 0}
        </Modal.Title>

        </Modal.Header>
        <Modal.Body className="table-responsive max-height">
          <p>Le montant de la Scolarite:</p>
             <CurrencyComponent amount={data ? data.total_amount : ''} currency="GNF" />
          <p>Recette total pour cette classe :</p>
             <CurrencyComponent amount={calculateTotalPayment() ? calculateTotalPayment(): ''} currency="GNF" />
             
          <hr />
          <SearchBar2
                    onSearch={handleSearch}
          />
          <hr />
          <div className="d-flex justify-content-between align-items-center mb-3">
            {/*<div>
              <Button
                variant="info"
                onClick={savePresenceStatus}
                disabled={isLoading}
                >
                  {isLoading ? "Encours..." : "Envoyer Presence"}
              </Button>
              {successMessage && <p>{successMessage}</p>}
            </div>*/}

            <Button variant="primary" onClick={openModal}>
              <FontAwesomeIcon icon={faAdd} /> Un elève
            </Button>
          </div>
          {loading ? (
            // Display a Spinner while loading
            <div className="text-center">
              <Spinner animation="border" role="status">
                <span className="sr-only"></span>
              </Spinner>
            </div>
          ) : filteredData && filteredData.length > 0 ? (
            // Render the details of the selected eleve-by-class as a table
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nom</th>
                  <th>Prenom</th>
                  {/* <th>Presence</th> */}
                  <th>Annee Academic</th>
                  <th>Voir</th>
                  {userData && userData[0].is_staff && (
                    <>
                    <th>Modif</th>
                    <th>Sup</th>
                  </>
                  )}
                </tr>
              </thead>
              <tbody>
                {filteredData.map((eleve) => (
                  <tr key={eleve.id}>
                    <td>{eleve.id}</td>
                    <td>{eleve.first_name}</td>
                    <td>{eleve.last_name}</td>
                    {/*<td>
                      <label style={labelStyles[eleve.id]}>
                        <input
                          type="checkbox"
                          name={`presence_${eleve.id}`}
                          checked={presenceStatus[eleve.id] || false}
                          onChange={(e) =>
                            handlePresenceChange(eleve.id, e.target.checked)
                          }
                        />{" "}
                        {presenceStatus[eleve.id] ? "Present" : "Absent"}
                        </label>
                      </td>*/}
                    <td>{getYearNameById(eleve.academic_year)}</td>
                    <td>
                      <Button
                        variant="info"
                        size="sm"
                        className="mr-2"
                        onClick={() => handleView(eleve.id)}
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </Button>
                    </td>
                    {userData && userData[0].is_staff && (
                      <>
                    <td>
                      <Button
                        variant="success"
                        size="sm"
                        className="mr-2"
                        //onClick={() => handleUpdate(eleve.id)}
                        onClick={() => openModal(eleve.id)}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </Button>
                    </td>
                    <td>
                      <Button
                        variant="danger"
                        size="sm"

                      //onClick={() => handleDelete(eleve.id)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </td>
                    </>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>Les données en cours the telechargement... patienter</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
      )}
      {/* Modals for viewing, updating, and deleting records */}
      {itemId !== null && (
        <ViewModalEleve
          isOpen={isViewModalOpen}
          onClose={handleCloseView}
          elevesData={elevesData.find((eleve) => eleve.id === itemId) || {}}
          totalAmount={data ? data.total_amount : 0}
        />
      )}
      <CreateRecordModal
        isOpen={modalIsOpen}
        onClose={closeModal}
        //onCreate={handleCreate}
        onCreate={(newRecord) => handleCreate(newRecord)}
        updateData={(newRecord) => setElevesData([...elevesData, newRecord])}
        apiUrl="/presms/eleves/"
        initialFormData={{
          first_name: '',
          last_name: '',
          date_of_birth: '',
          address: '',
          telephone: '',
          blood_group: '',
          email: '',
          previous_school_name: '',
          academic_year: '',
          //photo: '',
          class_assigned: data ? data.id: '',
        }}
      />
    </div>
  );
}
